<template>
  <div class="app flex-row align-items-center">
    <div class="container">
      <b-row class="justify-content-center">
        <b-col md="8">
          <b-card-group>
            <b-card no-body class="p-4">
              <b-card-body>
                <b-form v-on:keyup.enter="login()">
                  <h1>Login</h1>
                  <p class="text-muted">Melden Sie sich mit Ihren Zugangsdaten an</p>
                  <b-input-group class="mb-3">
                    <b-input-group-prepend><b-input-group-text><i class="icon-user"></i></b-input-group-text></b-input-group-prepend>
                    <b-form-input v-model="username" type="text" class="form-control" placeholder="Benutzername" autocomplete="username email" autofocus/>
                  </b-input-group>
                  <b-input-group class="mb-4">
                    <b-input-group-prepend><b-input-group-text><i class="icon-lock"></i></b-input-group-text></b-input-group-prepend>
                    <b-form-input v-model="password" type="password" class="form-control" placeholder="Passwort" autocomplete="current-password" />
                  </b-input-group>
                  <b-row>
                    <b-col cols="2">
                      <b-button variant="primary" class="px-4" v-on:click="login()">Anmelden</b-button>
                    </b-col>
                    <b-col>
                      <label class="text-danger">{{error}}</label>
                    </b-col>
                  </b-row>
                </b-form>
              </b-card-body>
            </b-card>
          </b-card-group>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'

export default {
    name: 'login',
    data() {
        // Sonderfall weil ich password nicht im store speichern will wegen Sicherheit
        return {
            username: '',
            password: '',
        }
    },
    computed: {
        ...mapGetters({
            authObj: 'getAuthObj',
            error: 'getError'
        })
    },
    methods: {
        login() {
            this.$store.dispatch('doLogin', {username: this.username, password: this.password})
        }
    },
    watch: {
        authObj: function(){
            if(this.authObj.token != ''){
              this.$router.push({name: "Unternehmen"})
            }
        }
    }
}
</script>
